<div class="container">
  <div class="row">
    <div class="col-xl-12">
      <div class="section_title text-center mb-100">
        <h3>Metode De plata</h3>
      </div>
    </div>
  </div>
  <div class="row">

    <app-payment-method [title]="'Tichete de vacanta Sodexo'"
                        [imageSource]="'../../../assets/img/payment/sodexo-logo.png'"
                        [paymentTypes]="voucherPaymentType"></app-payment-method>

    <app-payment-method [title]="'Tichete cadou Sodexo'"
                        [imageSource]="'../../../assets/img/payment/sodexo-logo.png'"
                        [paymentTypes]="voucherPaymentType"></app-payment-method>

    <app-payment-method [title]="'Tichete de vacanta Edenred'"
                        [imageSource]="'../../../assets/img/payment/Edenred_Logo.png'"
                        [paymentTypes]="voucherPaymentType"></app-payment-method>

    <app-payment-method [title]="'Tichete de vacanta UpRomania'"
                        [imageSource]="'../../../assets/img/payment/logo-up-romania.jpg'"
                        [paymentTypes]="voucherPaymentType"></app-payment-method>

    <app-payment-method [title]="'Card Bancar'"
                        [imageSource]="'../../../assets/img/payment/masterCardAndVisa.png'"
                        [paymentTypes]="['MasterCard','Visa']"></app-payment-method>

    <app-payment-method [title]="'Cash'"
                        [imageSource]="'../../../assets/img/payment/cash.jpg'"
                        [paymentTypes]="['RON']"></app-payment-method>
  </div>
</div>

<div class="offers_area padding_top">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="section_title text-center mb-100">
          <span>Ofertele noastre</span>
          <h3>Oferte active</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <app-offer class="col-xl-4 col-md-4" [title]="'Week-end 2800 RON'"
                 [imageSource]="'../../../assets/img/offers/fataH.jpeg'"
                 [features]="['Baie la fiecare camera','Până la 12 persoane', 'Bucătărie modernă', 'Living spațios', 'Peisaj de vis']"
                 [active]="true"></app-offer>


      <app-offer class="col-xl-4 col-md-4" [title]="'Craciun 4000 RON'"
                 [imageSource]="'../../../assets/img/offers/craciun.jpg'"
                 [features]="['Baie la fiecare camera','Până la 12 persoane', 'Bucătărie modernă', 'Living spațios', 'Peisaj de vis']"
                 [active]="true"></app-offer>


      <app-offer class="col-xl-4 col-md-4" [title]="'Revelion 10000 RON'"
                 [imageSource]="'../../../assets/img/offers/revelion.jpg'"
                 [features]="['Baie la fiecare camera','Până la 12 persoane', 'Bucătărie modernă', 'Living spațios', 'Peisaj de vis']"
                 [active]="false"></app-offer>
    </div>
  </div>
</div>

<div class="about_main_info">
  <div class="container">
    <div class="row">
      <div class="single_about_info">
        <h3>Bucătăria</h3>
        <p>Este dotată cu taote utilitățile necesare iar printre acestea se numără:</p>
        <ul>
          <li>Cuptor cu microunde</li>
          <li>Cuptor electric</li>
          <li>Plită electrică</li>
          <li>Mașină de spălat vase</li>
          <li>Frigider</li>
          <li>Veselă</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="kitchen-gallery">
    <ngx-gallery [options]="galleryOptions"
                 [images]="galleryImages"
                 class="ngx-gallery">
    </ngx-gallery>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-breadcam-area',
  templateUrl: './breadcam-area.component.html',
  styleUrls: ['./breadcam-area.component.css']
})
export class BreadcamAreaComponent implements OnInit {

  title: string;
  cssClass: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.router.url === '/about') {
      this.title = 'Despre Pensiunea Roua Apuseni Beliș';
      this.cssClass = 'bradcam_area breadcam_bg';
    } else {
      this.title = 'Contactați-ne';
      this.cssClass = 'bradcam_area breadcam_bg_2';
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HeaderInfo} from '../header/HeaderInfo';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  headerInfos: HeaderInfo[] = [
    new HeaderInfo('https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209', 'fb-logo', 'fa-facebook-square'),
    // tslint:disable-next-line:max-line-length
    new HeaderInfo('https://www.google.com/maps/place/Pensiunea+Roua+Apuseni+Beli%C8%99/@46.678898,23.0340815,17z/data=!3m1!4b1!4m5!3m4!1s0x4748e196f557c0d7:0x7f9e4b699908a8cf!8m2!3d46.678898!4d23.0362702', 'maps-logo', 'fa-maps-square'),
    new HeaderInfo('https://www.instagram.com/pensiunea.roua.belis/', 'insta-logo', 'fa-instagram')
  ];

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToRooms() {
    this.router.navigate(['rooms']);
    window.scroll(0, 0);
  }

  navigateToHome() {
    this.router.navigate(['home']);
    window.scroll(0, 0);
  }

  navigateToAbout() {
    this.router.navigate(['about']);
    window.scroll(0, 0);
  }

  navigateToContact() {
    this.router.navigate(['contact']);
    window.scroll(0, 0);
  }
}

<section class="contact-section">
  <div class="container">
    <div class=" mb-5 pb-4">
      <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude" [zoom]="zoom" [gestureHandling]="'cooperative'"
               [scrollwheel]="null">
        <agm-marker [latitude]="markerLatitude" [longitude]="markerLongitude" [iconUrl]="icon"
                    [title]="title" [animation]="animation" [openInfoWindow]="true"
                    (markerClick)="onMarkerClick()">
          <agm-info-window [disableAutoPan]="false">
            <div id="content">

              <div id="siteNotice"></div>

              <h1 id="firstHeading" class="firstHeading">Pensiunea Roua Apuseni Beliș</h1>

              <div id="bodyContent">
                <p>
                  <i class="fa fa-maps-square"></i>
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.google.com/maps/place/Pensiunea+Roua+Apuseni+Beli%C8%99/@46.678898,23.0340815,17z/data=!4m5!3m4!1s0x4748e196f557c0d7:0x7f9e4b699908a8cf!8m2!3d46.678898!4d23.0362702">
                    Indicații de orientare</a> sau
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.google.com/maps/place/Pensiunea+Roua+Apuseni+Beli%C8%99/@46.678898,23.0340815,17z/data=!4m5!3m4!1s0x4748e196f557c0d7:0x7f9e4b699908a8cf!8m2!3d46.678898!4d23.0362702">
                    vezi poziția</a>
                </p>

                <p>
                  <i class="fa fa-facebook-square"></i>

                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/">
                    Vizualizează pagina de Facebook
                  </a>
                </p>

                <p>
                  <i class="fa fa-instagram"></i>
                  <a class="info-window-link"
                     target="_blank" rel="noopener noreferrer"
                     href="https://www.instagram.com/pensiunea.roua.belis/">
                    Urmărește-ne pe Instagram
                  </a>
                </p>
              </div>
            </div>
          </agm-info-window>
        </agm-marker>
      </agm-map>
    </div>

    <div class="row">
      <div class="col-12 contact-us-section-title">
        <h2 class="contact-title">Contactați-ne</h2>
      </div>

      <div class="contact-info-container">
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-home"></i></span>
          <div class="media-body">
            <h3>
              <a id="contact-location"
                target="_blank" rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Pensiunea+Roua+Apuseni+Beli%C8%99/@46.678898,23.0340815,17z/data=!4m5!3m4!1s0x4748e196f557c0d7:0x7f9e4b699908a8cf!8m2!3d46.678898!4d23.0362702">
                Comuna Beliș, jud. Cluj, România
<!--                todo align problem-->
              </a>
            </h3>
          </div>
        </div>

        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-tablet"></i></span>
          <div class="media-body">
            <h3><a id="contact-tel1" href="tel:+40758065325">+ 40 758 065 325 - Monica</a><br><br>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- about_area_start -->
<div class="about_area">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-5">
        <div class="about_info">
          <div class="section_title mb-20px">
            <span>Despre noi</span>
            <h3>O pensiune modernă
              în natură</h3>
          </div>
          <p>Pensiunea Roua Apuseni Beliș dispune de o capacitate de 10 locuri.
            Vă punem la dispoziție 5 dormitoare cu baie proprie, un living cu bucătărie complet utilată și o terasă spațioasă cu grill și cuptor.
          </p>
        </div>
      </div>
      <div class="col-xl-7 col-lg-7">
        <div class="about_thumb d-flex">
          <div class="img_1">
            <img alt="" src="../../../assets/img/about/livingB.jpeg">
          </div>
          <div class="img_2">
            <img alt="" src="../../../assets/img/about/camera2.jpeg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- about_area_end -->

<!-- about_main_info_start -->
<div class="about_main_info">
  <div class="container">
    <div class="row">
      <div class="single_about_info">
        <h3>Perfectă pentru relaxare</h3>
        <p>Jacuzzi de 12 locuri.
          Internet WiFi în toată pensiunea.
          Living spatios si loc pentru servit masa cu priveliște deosebită.
          Toate dormitoarele au baie proprie.
          Terasa spatioasă cu mese si locuri pentru toată lumea cazată, iar pentru pregătirea preparatelor culinare, pentru a vă ușura munca si a vă face șederea la noi cât mai plăcută, vă sunt puse la dispoziție cuptor, grătar și loc de făcut ceaun.
          Locuri de parcare.
          Balansoare
        </p>
      </div>

      <div class="single_about_info">
        <h3>Accesul cu mașina</h3>
        <p>Drumul până la pensiune este asfaltat și accesul se poate realiza cu orice tip de mașină. Odată ajunși la
          pensiune, mașinile pot fi parcate in curte.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- about_main_info_end -->

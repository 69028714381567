import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AgmCoreModule} from '@agm/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home_page/home/home.component';
import {HeaderComponent} from './common_components/header/header.component';
import {SliderAreaComponent} from './home_page/slider-area/slider-area.component';
import {AboutAreaComponent} from './common_components/about-area/about-area.component';
import {OffersAreaComponent} from './common_components/offers-area/offers-area.component';
import {VideoAreaComponent} from './home_page/video-area/video-area.component';
import {FeatureRoomsComponent} from './common_components/feature-rooms/feature-rooms.component';
import {ForQueryComponent} from './common_components/for-query/for-query.component';
import {InstagramAreaComponent} from './common_components/instagram-area/instagram-area.component';
import {FooterComponent} from './common_components/footer/footer.component';
import {RoomsComponent} from './rooms_page/rooms/rooms.component';
import {OverlayComponent} from './rooms_page/overlay/overlay.component';
import {AboutComponent} from './about_page/about/about.component';
import {BreadcamAreaComponent} from './common_components/breadcam-area/breadcam-area.component';
import {AboutInfoAreaComponent} from './about_page/about-info-area/about-info-area.component';
import {AboutMainInfoComponent} from './about_page/about-main-info/about-main-info.component';
import {ContactComponent} from './contact_page/contact/contact.component';
import {ContactSectionComponent} from './contact_page/contact-section/contact-section.component';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {FlexLayoutModule} from '@angular/flex-layout';
import {OfferComponent} from './common_components/offers-area/offer/offer.component';
import {PaymentMethodsComponent} from './about_page/payment-methods/payment-methods.component';
import {PaymentMethodComponent} from './about_page/payment-methods/payment-method/payment-method.component';
import {ActivitiesComponent} from './common_components/activities/activities.component';
import {KitchenComponent} from './about_page/kitchen/kitchen.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SliderAreaComponent,
    AboutAreaComponent,
    OffersAreaComponent,
    VideoAreaComponent,
    FeatureRoomsComponent,
    ForQueryComponent,
    InstagramAreaComponent,
    FooterComponent,
    RoomsComponent,
    OverlayComponent,
    AboutComponent,
    BreadcamAreaComponent,
    AboutInfoAreaComponent,
    AboutMainInfoComponent,
    ContactComponent,
    ContactSectionComponent,
    OfferComponent,
    PaymentMethodsComponent,
    PaymentMethodComponent,
    ActivitiesComponent,
    KitchenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGalleryModule,
    FlexLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCyQZdrdFFIakFu1H8bVJZOrXpyVT6S5Bo'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

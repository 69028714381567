import {Component, OnInit} from '@angular/core';
import {ImageInformation} from './ImageInformation';

@Component({
  selector: 'app-instagram-area',
  templateUrl: './instagram-area.component.html',
  styleUrls: ['./instagram-area.component.css']
})
export class InstagramAreaComponent implements OnInit {

  instagramImageInformation: ImageInformation[] = [
    new ImageInformation('../../../assets/img/instagram/insta1.jpg', 'https://www.instagram.com/p/CQBvUdntVZX/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta2.jpg', 'https://www.instagram.com/p/CJyWjcOjZdB/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta3.jpg', 'https://www.instagram.com/p/CItU8E5B1a6/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta4.jpg', 'https://www.instagram.com/p/CIYRbUvB6jR/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/insta5.jpg', 'https://www.instagram.com/p/CIYPr05haAb/', 'instagram'),
    new ImageInformation('../../../assets/img/instagram/fb1.jpg', 'https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/photos/102188155109997', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb2.jpg', 'https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/photos/156145349714277', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb3.jpg', 'https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/photos/102022968459849', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb4.jpg', 'https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/photos/102188261776653', 'facebook'),
    new ImageInformation('../../../assets/img/instagram/fb5.jpg', 'https://www.facebook.com/Pensiunea-Roua-Apuseni-Belis-102022701793209/photos/112058844122928', 'facebook')
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';

export class GalleryOptions {
  getGalleryOptions() {
    return [
      {
        width: '100%',
        height: '800px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Zoom,
        thumbnails: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 3000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageDescription: true,
        imagePercent: 100,
        imageArrowsAutoHide: true,
        lazyLoading: true,
        previewBullets: true,
        imageSwipe: true,
        previewCloseOnEsc: true,
        previewArrowsAutoHide: true,
        previewKeyboardNavigation: true,
        previewAutoPlayInterval: 3000,
        previewAutoPlayPauseOnHover: true,
        previewInfinityMove: true,
        previewZoom: true,
        previewZoomStep: 0.2,
        previewZoomMax: 2.2,
        previewZoomMin: 0.4,
        previewDownload: true,
        previewSwipe: true,
        thumbnailsSwipe: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageAnimation: NgxGalleryAnimation.Zoom,
        thumbnails: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 3000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageDescription: true,
        imageArrowsAutoHide: true,
        lazyLoading: true,
        previewBullets: true,
        imageSwipe: true,
        previewCloseOnEsc: true,
        previewArrowsAutoHide: true,
        previewKeyboardNavigation: true,
        previewAutoPlayInterval: 3000,
        previewAutoPlayPauseOnHover: true,
        previewInfinityMove: true,
        previewZoom: true,
        previewZoomStep: 0.2,
        previewZoomMax: 2.2,
        previewZoomMin: 0.4,
        previewDownload: true,
        previewSwipe: true,
        thumbnailsSwipe: true,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
        imageSwipe: true,
        imageAnimation: NgxGalleryAnimation.Zoom,
        thumbnails: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 3000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageDescription: true,
        imagePercent: 100,
        imageArrowsAutoHide: true,
        lazyLoading: true,
        previewBullets: true,
        previewCloseOnEsc: true,
        previewArrowsAutoHide: true,
        previewKeyboardNavigation: true,
        previewAutoPlayInterval: 3000,
        previewAutoPlayPauseOnHover: true,
        previewInfinityMove: true,
        previewZoom: true,
        previewZoomStep: 0.2,
        previewZoomMax: 2.2,
        previewZoomMin: 0.4,
        previewDownload: true,
        previewSwipe: true,
        thumbnailsSwipe: true,
      }
    ];
  }
}

<!-- features_room_start -->
<div class="features_room">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="section_title text-center mb-100">
          <span>Camere superbe</span>
          <h3>Alege camera potrivită</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="rooms_here">
    <div class="gallery-container">
      <ngx-gallery [options]="galleryOptions" [images]="doubleRoom" class="ngx-gallery"></ngx-gallery>
    </div>
    <div class="gallery-container">
      <ngx-gallery [options]="galleryOptions" [images]="parter" class="ngx-gallery"></ngx-gallery>
    </div>
  </div>
</div>
<!-- features_room_end -->

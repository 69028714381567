<!-- slider_area_start -->
<div class="slider_area">
  <div class="slider_active owl-carousel">
    <div class="single_slider d-flex align-items-center justify-content-center slider_bg_1">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="slider_text text-center">
              <h3>Pensiunea Roua Apuseni Beliș</h3>
              <p>Hai la noi la relaxare</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="single_slider  d-flex align-items-center justify-content-center slider_bg_2">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="slider_text text-center">
              <h3>Viața este frumoasă</h3>
              <p>Într-un asemenea loc minunat</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="single_slider d-flex align-items-center justify-content-center slider_bg_6">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="slider_text text-center">
              <h3>Un loc de vis</h3>
              <p>Pentru un week-end și nu numai</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- slider_area_end -->

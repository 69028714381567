<div class="container">
  <div class="row">
    <div class="section_title mb-100">
      <h3>Activitați</h3>
      <p>Oferim turiștilor noștri mai multe posibilitati de a petrece timpul liber:</p>
    </div>
  </div>
  <div class="row">
      <div class="activity">
        <p>Jacuzzi:</p>
        <img src="assets/img/activities/ciubar.jpeg" alt="">
        <ul>
          <li>Incalzit</li>
          <li>Iluminat</li>
        </ul>
      </div>

    <div class="activity">
      <p>Mâncare afară:</p>
      <img src="assets/img/activities/gratar.jpeg" alt="">
      <ul>
        <li>Gratar</li>
        <li>Ceaun</li>
        <li>Disc</li>
      </ul>
    </div>

      <div class="activity">
        <p>Board games:</p>
        <img src="assets/img/activities/boardGames.jpg" alt="">
        <ul>
          <li>Activity</li>
          <li>Catan</li>
          <li>Remy</li>
          <li>Carti de joc</li>
          <li>Sah</li>
          <li>Table</li>
        </ul>
      </div>

    <div class="activity">
      <p>Sporturi de iarna:</p>
      <img src="assets/img/activities/ski.jpg" alt="">
      <ul>
        <li>Ski</li>
        <li>Snowboard</li>
      </ul>
    </div>

      <div class="activity" style="margin-top: auto; margin-bottom: auto; text-align: center">
        <p>Echitație</p>
        <img src="assets/img/activities/echitatie.jpg" alt="">
      </div>

      <div class="activity">
        <p>Sanie trasă de cai</p>
        <img src="assets/img/activities/sanie.jpg" alt="">
      </div>

  </div>
  <div class="more_info">
    Prețul pentru activitățile ce implică cai este separat de cel de închiriere al cabanei și acestea trebuie
    discutate in prealabil cu proprietarul cailor: <a [href]="'tel:+40755816128'">Stelian - 0755816128</a>
  </div>
</div>


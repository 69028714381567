<!-- footer -->
<footer class="footer">
  <div class="footer_top">
    <div class="container">
      <div class="row">
        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              adresa
            </h3>
            <p class="footer_text"> Comuna Beliș <br> Jud. Cluj, România</p>
            <a class="line-button"
               target="_blank" rel="noopener noreferrer"
               href="https://www.google.com/maps/place/Pensiunea+Roua+Apuseni+Beli%C8%99/@46.678898,23.0340815,17z/data=!3m1!4b1!4m5!3m4!1s0x4748e196f557c0d7:0x7f9e4b699908a8cf!8m2!3d46.678898!4d23.0362702">Localizare</a>
          </div>
        </div>

        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              Rezervare
            </h3>
            <ul>
              <li>
                <a href="tel:+40758065325">+ 40 758 065 325 - Monica</a><br>
              </li>
            </ul>
            <div class="ue">
              <a href="https://afir.info/" target="_blank" rel="noopener noreferrer">
                <img class="ue-logo" src="assets/img/logoAFIR.png" alt="UE_Logo">
              </a>
            </div>
          </div>
        </div>

        <div>
          <div class="footer_widget">
            <h3 class="footer_title">
              Navigare
            </h3>
            <ul>
              <li>
                <a style="cursor: pointer"
                   href=""
                   (click)="navigateToHome()">
                  Acasă
                </a>
              </li>
              <li>
                <a style="cursor: pointer"
                   (click)="navigateToRooms()">
                  Camere
                </a>
              </li>
              <li>
                <a style="cursor: pointer"
                   (click)="navigateToAbout()">
                  Despre
                </a>
              </li>
              <li>
                <a style="cursor: pointer"
                   (click)="navigateToContact()">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="social-media">
          <div class="footer_widget">
            <h3 class="footer_title">
              Social Media
            </h3>
            <ul class="socail_links">
              <li *ngFor="let headerInfo of headerInfos">
                <a href="{{headerInfo.link}}" target="_blank" rel="noopener noreferrer">
                  <i id="{{headerInfo.id}}" class="fa {{headerInfo.classAttr}}"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

import {Component, OnInit} from '@angular/core';
import {ContactInfo} from './ContactInfo';

@Component({
  selector: 'app-for-query',
  templateUrl: './for-query.component.html',
  styleUrls: ['./for-query.component.css']
})
export class ForQueryComponent implements OnInit {

  contactInfos: ContactInfo[] = [
    new ContactInfo('+ 40 758 065 325 - Monica', '+40758065325')
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-feature-rooms',
  templateUrl: './feature-rooms.component.html',
  styleUrls: ['./feature-rooms.component.css']
})
export class FeatureRoomsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  parter: NgxGalleryImage[];
  etaj4CuBalcon: NgxGalleryImage[];
  etaj4FaraBalcon: NgxGalleryImage[];
  doubleRoom: NgxGalleryImage[];

  parterDescription = 'Trei camere pentru 2 persoane: ' +
    '<br> -două paturi single' +
    '<br> -baie proprie';

  dublaDescription = 'Trei camere duble: ' +
    '<br> -pat matrimonial' +
    '<br> -baie proprie';
  // etaj4CuBalconDescription = 'Două camere pentru 4 persoane la etaj: ' +
  //   '<br> -pat matrimonial' +
  //   '<br> -două paturi single' +
  //   '<br> -balcon' +
  //   '<br> -baie proprie';
  // etaj4FaraBalconDescription = 'O cameră pentru 4 persoane la etaj: ' +
  //   '<br> -pat matrimonial' +
  //   '<br> -două paturi single' +
  //   '<br> -baie proprie';

  constructor() {
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '600px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Zoom,
        thumbnails: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 3000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageDescription: true,
        imagePercent: 100,
        imageArrowsAutoHide: true,
        lazyLoading: true,
        previewBullets: true,
        imageSwipe: true,
        previewCloseOnEsc: true,
        previewArrowsAutoHide: true,
        previewKeyboardNavigation: true,
        previewAutoPlayInterval: 3000,
        previewAutoPlayPauseOnHover: true,
        previewInfinityMove: true,
        previewZoom: true,
        previewZoomStep: 0.2,
        previewZoomMax: 2.2,
        previewZoomMin: 0.4,
        previewDownload: true,
        previewSwipe: true,
        thumbnailsSwipe: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
        imageSwipe: true
      }
    ];

    this.doubleRoom = [
      {
        medium: 'assets/img/rooms/dubla/1.jpeg',
        big: 'assets/img/rooms/dubla/1.jpeg',
        description: this.dublaDescription,
      },
      {
        medium: 'assets/img/rooms/dubla/2.jpeg',
        big: 'assets/img/rooms/dubla/2.jpeg',
        description: this.dublaDescription,
      },
      // {
      //   medium: 'assets/img/rooms/dubla/3.jpg',
      //   big: 'assets/img/rooms/dubla/3.jpg',
      //   description: this.dublaDescription,
      // },
      // {
      //   medium: 'assets/img/rooms/dubla/4.jpg',
      //   big: 'assets/img/rooms/dubla/4.jpg',
      //   description: this.dublaDescription,
      // },
      // {
      //   medium: 'assets/img/rooms/dubla/5.jpg',
      //   big: 'assets/img/rooms/dubla/5.jpg',
      //   description: this.dublaDescription,
      // }
    ];

    this.parter = [
      // todo add pictures for parter room
      {
        medium: 'assets/img/rooms/1.jpeg',
        big: 'assets/img/rooms/1.jpeg',
        description: this.parterDescription,
      },
      {
        medium: 'assets/img/rooms/2.jpeg',
        big: 'assets/img/rooms/2.jpeg',
        description: this.parterDescription,
      },
      {
        medium: 'assets/img/rooms/3.jpeg',
        big: 'assets/img/rooms/3.jpeg',
        description: this.parterDescription,
      },
    //   {
    //     medium: 'assets/img/rooms/4.png',
    //     big: 'assets/img/rooms/4.png',
    //     description: this.parterDescription,
    //   }
      ];

    // this.etaj4CuBalcon = [
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/1.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/1.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/2.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/2.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/3.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/3.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/4.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/4.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/5.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/5.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/6.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/6.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/7.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/7.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/8.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/8.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/9.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/9.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4CuBalcon/10.jpg',
    //     big: 'assets/img/rooms/etaj4CuBalcon/10.jpg',
    //     description: this.etaj4CuBalconDescription,
    //   }
    // ];
    //
    // this.etaj4FaraBalcon = [
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/1.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/1.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/2.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/2.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/3.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/3.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/4.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/4.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/5.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/5.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/6.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/6.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   },
    //   {
    //     medium: 'assets/img/rooms/etaj4FaraBalcon/7.jpg',
    //     big: 'assets/img/rooms/etaj4FaraBalcon/7.jpg',
    //     description: this.etaj4FaraBalconDescription,
    //   }
    // ];
  }
}
